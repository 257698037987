exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "html,\nbody,\n@supports(padding:max(0px)) {\n    body,\n    header,\n    footer {\n        padding-left: min(0vmin, env(safe-area-inset-left));\n        padding-right: min(0vmin, env(safe-area-inset-right));\n    }\n}\n\n.index_app_2mqDO {\n    /* probably unecessary, transitional until layout is refactored */\n    width: 100vw;\n    height: 95vh;\n    margin: 0;\n    margin-top: -10px;\n\n    margin-left: -8px;\n    padding-left: calc(\n        env(safe-area-inset-left) + (-14px)\n    ); /* Apply safe area */\n\n    /* Setting min height/width makes the UI scroll below those sizes */\n    min-width: 100vw;\n    min-height: 95vh; /* Min height to fit sprite/backdrop button */\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* {\n    -webkit-box-sizing: border-box;\n            box-sizing: border-box;\n}\n", ""]);

// exports
exports.locals = {
	"app": "index_app_2mqDO"
};